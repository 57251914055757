<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '编辑配送员' : '创建配送员'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="名称" prop="name">
          <el-input
            v-model.trim="form.data.name"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="account">
          <el-input
            v-model.trim="form.data.account"
            maxlength="11"
            placeholder="请输入联系电话"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="!isChange">
          <el-input
            v-model.trim="form.data.password"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <!-- 超级管理员选地区 -->
        <el-form-item label="所属地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="form.data.area"
            :options="areaArr"
            :props="{ checkStrictly: false }"
            clearable
            filterable
            style="width:100%;"
            @change="getStore">
          </el-cascader>
        </el-form-item>
        <el-form-item label="配送路线" prop="store_ids">
          <el-select v-model="form.data.store_ids" multiple filterable  class="width100">
            <el-option v-for="item in storeArr" :label="item.name" :value="item.id" :key="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.merchant_name }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { REG_EXP } from '@/util'
  import { getStorage } from '@/storage'
  export default {
    name: 'ItineraryEdit',
    data() {
      return {
        roleType: getStorage('role_type'),
        login_province: getStorage('province'),
        login_city: getStorage('city'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            admin_id:0,
            name:'',
            account: '',
            password: '',
            area: [],
            province: '',
            city: '',
            store_ids: [],
            storeArr: [],
          },
          rules: {
            name: [{ required: true, message:'请输入名称', trigger: 'change'}],
            account: [
              { required: true, message:'请输入联系电话', trigger: 'change'},
              {
                pattern: REG_EXP.mobilePhone,
                message: '请输入正确的手机号',
                trigger: 'change'
              }
            ],
            password: [{ required: true, message:'输入密码', trigger: 'change'}],
            area: [{ required: true, message:'请选择地区', trigger: 'change'}],
          }
        },
        areaArr: [],
        storeArr: []
      }
    },
    methods: {
      getDetail(row) {
        this.getArea();
        if(this.isChange == false) {
          this.getStore();
        }
        if(!!row.id) {
          this.isChange = true
          this.$nextTick(() => {
            common.deepClone(this.form.data, row)
            this.form.data.area = [row.province, row.city];
            this.getStore();
            if(!!row.store_ids) {
              this.form.data.store_ids = row.store_ids.split("|").map((item) => {return Number(item)})
            }
          })
        }
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 获取全国省市区
      getArea() {
        this.$http.post('/common/district/all', {is_all:1}).then(res => {
          if(res.code === 1) {
            this.areaArr = res.data.list;
            for (const key in this.areaArr) {
              // 删除第三层children属性
              for (const i in this.areaArr[key].children) {
                delete this.areaArr[key].children[i].children
              }
            }
          }
        })
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = {
              id: this.form.data.id,
              name: this.form.data.name,
              account: this.form.data.account,
              password: this.form.data.password,
              province: this.roleType == 1 ? this.form.data.area[0] : this.login_province,
              city: this.roleType == 1 ? this.form.data.area[1] : this.login_city,
              store_ids: !!this.form.data.store_ids ? this.form.data.store_ids.join("|") : '',
            };
            console.log(_params)
            let apiUrl = this.isChange ? '/admin/path/edit' : '/admin/path/add'
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      // 根据地区查客户的食堂列表
      getStore() {
        this.form.data.store_ids = [];
        let _params = {
          province: this.roleType == 1 ? this.form.data.area[0] : this.login_province,
          city: this.roleType == 1 ? this.form.data.area[1] : this.login_city,
          admin_id: !!this.isChange ? this.form.data.id : 0, // 创建配送员工时admin_id传0，编辑时就传配送员工id
          page:1,
          count:10000,
        }
        this.$http.get('/admin/path/storeList', {params:_params}).then(res => {
          if(res.code === 1) {
            this.storeArr = res.data.list;
          }
        })
      },
    }
  }
</script>